import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { AuthenticationService } from 'core/authentication.service';
import { ErrorService } from 'core/error.service';
import { GetAdministrativeUnitsResponse } from 'api/responses';
import { GetAdministrativeUnitsResult } from 'api/results';
import { ApiInfoDto } from 'api/models/api-info-dto';
import { environment } from 'environments/environment';

@Injectable()
export class ApiInfoService {
  constructor(private http: HttpClient) {}

  getInfo(): Observable<ApiInfoDto> {
    return this.http.get<ApiInfoDto>(`${environment.apiHost}api-info`);
  }
}
