import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { NgxWebstorageModule } from 'ngx-webstorage';

import { AuthenticationService } from './authentication.service';
import { AuthGuardService } from './auth.guard';
import { ErrorService } from 'core/error.service';
import { httpInterceptorProviders } from './interceptors';
import { ApiInfoService } from './api.info.service';
import { SaveChangesGuard } from './save-changes-guard';

@NgModule({
  imports: [CommonModule, HttpClientModule, NgxWebstorageModule],
  declarations: [],
  providers: [
    AuthenticationService,
    AuthGuardService,
    SaveChangesGuard,
    ErrorService,
    ApiInfoService,
    httpInterceptorProviders
  ]
})
export class CoreModule {}
