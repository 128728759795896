import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './core/auth.guard';
import { UserInfoResolverService } from './core/user-info-resolver.service';
import { ContentComponent } from './content.component';
import { CreateUserComponent } from 'account/create-user.component';
import { LoginComponent } from 'account/login.component';
import { TenantSettingsResolverService } from 'core/tenant-settings-resolver.service';
import { NoAccessPage, NotFoundPage, AboutPage } from './pages';
import { AdministrationRole, Role } from 'api/enums';
import { ResetPasswordPage } from 'account/pages';

const routes: Routes = [
  { path: 'create-user/:invitationId', component: CreateUserComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password/:userId/:resetToken', component: ResetPasswordPage },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    resolve: {
      userInfo: UserInfoResolverService,
      tenantSettings: TenantSettingsResolverService
    },
    children: [
      { path: '', redirectTo: 'cases', pathMatch: 'full' },
      { path: 'about', component: AboutPage },

      {
        path: 'cases',
        loadChildren: () =>
          import('./cases/cases.module').then(m => m.CasesModule),
        canActivate: [AuthGuard],
        data: {
          userRoles: Role.CaseManager | Role.FirstLine | Role.Customer
        }
      },
      // {
      //   path: 'transitcase',
      //   loadChildren: () =>
      //     import('./transit-case/transit-case.module').then(
      //       m => m.TransitCaseModule
      //     ),
      //   canActivate: [AuthGuard],
      //   data: {
      //     userRoles: Role.CaseManager | Role.FirstLine | Role.Customer
      //   }
      // },

      {
        path: 'warehouse',
        loadChildren: () =>
          import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
        canActivate: [AuthGuard],
        data: {
          userRoles: Role.CaseManager
        }
      },

      {
        path: 'tw',
        loadChildren: () =>
          import(
            './transactional-warehouse/transactional-warehouse.module'
          ).then(m => m.TransactionalWarehouseModule),
        canActivate: [AuthGuard],
        data: {
          userRoles: Role.CaseManager
        }
      },

      {
        path: 'ocr',
        loadChildren: () => import('./ocr/ocr.module').then(m => m.OcrModule),
        canActivate: [AuthGuard],
        data: {
          userRoles: Role.CaseManager
        }
      },

      {
        path: 'financials',
        loadChildren: () =>
          import('./financials/financials-routing.module').then(
            m => m.FinancialsRoutingModule
          ),
        canActivate: [AuthGuard],
        data: {
          userRoles: Role.CaseManager,
          adminRoles: AdministrationRole.FinancialsAdministration
        }
      },

      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin-routing.module').then(
            m => m.AdminRoutingModule
          ),
        canActivate: [AuthGuard],
        data: {
          adminRoles: AdministrationRole.All
        }
      },

      {
        path: 'account',
        loadChildren: () =>
          import('./account/account-routing.module').then(
            m => m.AccountRoutingModule
          ),
        canActivate: [AuthGuard],
        data: {
          userRoles: Role.CaseManager | Role.Customer
        }
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./integrations/integrations-routing.module').then(
            m => m.IntegrationsRoutingModule
          )
      },

      { path: 'no-access', component: NoAccessPage },
      { path: 'not-found', component: NotFoundPage },
      { path: '**', redirectTo: '/not-found' }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
