import { Component, OnInit } from '@angular/core';

import { ContentTitleService } from 'app/content-title.service';

@Component({
  selector: '[ecom-about]',
  templateUrl: './about.page.html'
})
export class AboutPage implements OnInit {
  public title: string;

  constructor(private contentService: ContentTitleService) {}

  ngOnInit() {
    this.contentService.pageTitleChanged.subscribe(t => (this.title = t));
    this.contentService.setTitle('Om ECOM', 'Om ECOM');
  }
}
