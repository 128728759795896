import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import * as Sentry from '@sentry/angular-ivy';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxMdModule } from 'ngx-md';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AccountModule } from './account/account.module';
import { ContentComponent } from './content.component';
import { ContentTitleService } from './content-title.service';
import { SharedModule, HttpLoaderFactory } from './shared/shared.module';

import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import localeSvExtra from '@angular/common/locales/extra/sv';
import { NoAccessPage, NotFoundPage, AboutPage } from './pages';
import { environment } from 'environments/environment';

registerLocaleData(localeSv, 'sv', localeSvExtra);

export function errorHandlerFactory() {
  if (environment.production) {
    return Sentry.createErrorHandler({
      showDialog: true
    });
  }
  return new ErrorHandler();
}

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    AboutPage,
    NotFoundPage,
    NoAccessPage
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreModule,
    AccountModule,
    NgxMdModule.forRoot(),
    NgxWebstorageModule.forRoot({ prefix: 'ecom' }),
    // FragmentPolyfillModule.forRoot({ smooth: true }),
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // Keep this last so the wildcard route gets added last of all
    AppRoutingModule
  ],
  providers: [
    ContentTitleService,
    { provide: LOCALE_ID, useValue: 'sv' },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
