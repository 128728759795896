<div class="heightAll overflow mainBar">
  <div class="colPadding">
    <div class="uk-width-1-1">
      <p>
        Länken som du angav fungerar inte, antingen är sidan borttagen eller
        flyttad. Om du klickade på en länk vänligen meddela ansvarig på den
        webbplatsen att länken är felaktig.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  </div>
</div>
