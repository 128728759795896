import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { GetTenantSettingsResult } from 'api/results/get-tenant-settings-result.enum';
import { TenantService } from 'app/tenants/services/tenant.service';
import { TenantSettings } from './tenant-settings.model';

export const TenantSettingsResolverService: ResolveFn<TenantSettings> = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const tenantService: TenantService = inject(TenantService);
  return tenantService
    .getSettings()
    .toPromise()
    .then(settings => {
      if (settings.result === GetTenantSettingsResult.Success)
        return new TenantSettings(settings);

      return null;
    })
    .catch(_e => {
      return null;
    });
}
