import { GetTenantSettingsResponse } from 'api/responses/get-tenant-settings-response';
import { DelegateType } from 'api/enums/delegate-type.enum';

export class TenantSettings {
  private _defaultDelegateId: number;
  private _defaultDelegateType: DelegateType;
  private _name: string;
  private _receiver: string;
  private _sender: string;
  private _defaultLocale: string;

  public get defaultDelegateId(): number {
    return this._defaultDelegateId;
  }
  public get defaultDelegateType(): DelegateType {
    return this._defaultDelegateType;
  }
  public get name(): string {
    return this._name;
  }
  public get receiver(): string {
    return this._receiver;
  }
  public get sender(): string {
    return this._sender;
  }
  public get defaultLocale(): string {
    return this._defaultLocale;
  }

  constructor(response: GetTenantSettingsResponse) {
    this._defaultDelegateId = response.defaultDelegateId;
    this._defaultDelegateType = response.defaultDelegateType;
    this._name = response.name;
    this._receiver = response.receiver;
    this._sender = response.sender;
    this._defaultLocale = response.defaultLocale;
  }
}
