import { Component, OnInit } from '@angular/core';

import { ContentTitleService } from 'app/content-title.service';

@Component({
  selector: '[ecom-not-found]',
  templateUrl: './not-found.page.html'
})
export class NotFoundPage implements OnInit {
  constructor(private contentService: ContentTitleService) {}

  ngOnInit() {
    this.contentService.setTitle('Felaktig länk', 'Felaktig länk');
    // FIXME: Report to the API so we can log and maybe fix
  }
}
