import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, Resolve, ResolveFn, RouterState } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { UserInfoDto } from 'api/models';


export const UserInfoResolverService: ResolveFn<UserInfoDto> =
(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService: AuthenticationService = inject(AuthenticationService);
  const router: Router = inject(Router);

  return authService
    .getUserInfo()
    .toPromise()
    .then(userInfo => {
      if (userInfo) {
        return userInfo;
      } else {
        router.navigate(['/login'], {
          queryParams: { returnTo: state.url }
        });
        return null;
      }
    })
    .catch(_e => {
      authService.logOut();
      router.navigate(['/login'], { queryParams: { err: 500 } });
      return null;
    });
  }
