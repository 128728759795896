<div class="heightAll overflow mainBar">
  <div class="colPadding">
    <div class="uk-width-1-1">
      <h2 *ngIf="title">{{title}}</h2>

      <p>Skriv några ord om ECOM här</p>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec dapibus,
        nibh sit amet malesuada viverra, sem ligula fringilla orci, sit amet
        porttitor ex sapien facilisis neque. Cras ullamcorper aliquam ex eu
        tincidunt. Mauris elementum id enim vel facilisis. Duis a hendrerit
        urna, sit amet aliquam augue. Sed vitae porttitor mi, et interdum
        turpis. Morbi elementum venenatis nunc, in tristique massa cursus in.
        Morbi sollicitudin lacus in dolor efficitur ullamcorper. Quisque at
        velit et leo pretium viverra sit amet nec elit. Curabitur facilisis
        nulla vitae arcu suscipit faucibus. Quisque at magna orci. Sed non
        accumsan metus, id volutpat mauris. Pellentesque volutpat ultrices felis
        nec venenatis. Pellentesque rhoncus tempor lectus, quis accumsan velit
        pulvinar in. Proin et porttitor lacus, et vulputate est. Integer sem
        tortor, ultricies dignissim magna nec, blandit feugiat augue.
      </p>

      <p>
        Mauris tempus consequat mauris. Aliquam gravida est eu urna bibendum, ac
        rhoncus eros ultrices. Nunc a suscipit dolor. Nam rhoncus leo vitae dui
        mollis, sed commodo sapien auctor. Ut aliquet quis turpis sollicitudin
        varius. Praesent non laoreet mauris, in scelerisque ante. Pellentesque
        in consectetur massa. Vivamus sagittis sit amet nibh non laoreet. Duis
        sed risus rhoncus, ultrices nulla ac, malesuada quam. Aenean tortor
        quam, interdum id erat id, sollicitudin interdum ipsum. Pellentesque
        consequat arcu quis lorem posuere, nec dictum nisl facilisis. Etiam
        volutpat molestie lorem commodo ornare.
      </p>

      <p>
        Donec vel felis eros. Phasellus vitae hendrerit mauris. Nunc consectetur
        augue in ipsum blandit, vitae porta dui placerat. Nullam faucibus non mi
        non venenatis. Morbi ex neque, ultrices vel ante id, iaculis pharetra
        quam. Nunc sit amet mattis urna, at sollicitudin ligula. Nullam dictum
        elementum odio in vulputate. Vestibulum pellentesque at libero vel
        vestibulum. Cras blandit et tortor quis vehicula.
      </p>

      <p>
        Nullam ac pulvinar urna. Phasellus eget porta nulla, ac laoreet est.
        Suspendisse eleifend leo enim, non varius quam cursus imperdiet.
        Vestibulum mattis nulla vel leo hendrerit molestie at nec dui. Nullam
        volutpat quis urna ut vulputate. Donec in pulvinar nisi. Nullam augue
        ante, pretium ac tellus sit amet, ornare luctus metus. Pellentesque
        placerat dolor quis neque dapibus, ornare vulputate tortor laoreet. In
        hac habitasse platea dictumst. Ut congue ut tellus sed dictum. Cras
        aliquet tincidunt tempus. Integer mattis nulla neque, non dictum massa
        pellentesque placerat. Donec auctor ornare sapien. Proin et auctor dui.
      </p>
    </div>
  </div>
</div>
