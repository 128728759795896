import { filter, map } from 'rxjs/operators';
import {
  Component,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  ViewChild,
  ErrorHandler,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';
import some from 'lodash-es/some';
import orderBy from 'lodash-es/orderBy';
import uniqBy from 'lodash-es/uniqBy';
import { AuthenticationService } from './core/authentication.service';
import { ContentTitleService } from './content-title.service';
import { PresenceService } from 'shared/services/presence.service';
import { TranslateService } from '@ngx-translate/core';
import { PresenceDto, UserInfoDto, UserCompanyDto } from 'api/models';
import { IOModalService } from 'shared/modal/io-modal.service';
import { environment } from 'environments/environment';
import { TenantSettings } from 'core/tenant-settings.model';
import { AdministrationRole, Role } from 'api/enums';
import { ApiInfoService } from 'core/api.info.service';
import { Observable } from 'rxjs';
import { PopoverService } from 'shared/popover/popover.service';

@Component({
  selector: 'ecom-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit, OnDestroy, AfterViewChecked {
  Roles = Role;
  AdminRoles = AdministrationRole;

  isCollapsed = true;
  adminRoles = AdministrationRole;
  userTypes = Role;
  userInfo: UserInfoDto;
  title: string;
  titleSubscription: any;
  currentCompanyId: string;
  tenantSettings: TenantSettings;
  showLayout: boolean = true;

  // Presence
  presence: PresenceDto[] = [];

  public get version() {
    return environment.version;
  }

  public apiVersion: Observable<string>;

  constructor(
    private authenticationService: AuthenticationService,
    private contentService: ContentTitleService,
    private presenceService: PresenceService,
    private localStorage: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private viewContainerRef: ViewContainerRef,
    private ioModalService: IOModalService,
    private apiInfoService: ApiInfoService,
    private confirmService: PopoverService
  ) {
    translate.setDefaultLang('sv');
    translate.use('sv');
  }

  logOut() {
    this.authenticationService.logOut();
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('ecom-loaded');

    this.apiVersion = this.apiInfoService.getInfo().pipe(map(i => i.version));

    this.route.queryParams.subscribe(params => {
      if (params['windowed']) {
        this.showLayout = false;
      }
    });

    this.titleSubscription = this.contentService.pageTitleChanged.subscribe(
      (title: string) => (this.title = title)
    );
    this.route.data.subscribe(
      (data: { userInfo: UserInfoDto; tenantSettings: TenantSettings }) => {
        this.userInfo = data.userInfo;
        this.contentService.setUserId(this.userInfo.userId);
        this.contentService.setUserInfo(this.userInfo);

        this.tenantSettings = data.tenantSettings;
        this.translate.setDefaultLang(
          this.tenantSettings.defaultLocale || 'sv'
        );
        this.translate.use(this.tenantSettings.defaultLocale || 'sv');

        // Load presence
        this.presenceService
          .getPresence()
          .toPromise()
          .then(res => {
            this.presence = uniqBy(res.presence, p => p.user.id);
          });

        if (this.userInfo.companies) {
          const companyId = this.localStorage.retrieve('activeCompany');
          if (
            companyId != null &&
            some(
              this.userInfo.companies,
              (c: UserCompanyDto) => c.id === companyId
            )
          )
            this.switchCompany(companyId, false);
          else this.switchCompany(this.userInfo.companies[0].id, false);
        }

        if (this.userInfo.mainDepartment)
          this.contentService.setMainDepartment(this.userInfo.mainDepartment);
        if (this.userInfo.departments)
          this.contentService.setDepartments(this.userInfo.departments);

        if (this.userInfo.hasMultipleUserRoles) {
          const viewMode = this.localStorage.retrieve('activeViewMode');
          if (viewMode != null) {
            if (this.userInfo.hasUserRole(viewMode))
              this.switchViewMode(
                viewMode,
                this.userInfo.viewMode !== viewMode
              );
          }
        }
      }
    );
    this.ioModalService.setViewContainerRef(this.viewContainerRef);
    this.confirmService.setViewContainerRef(this.viewContainerRef);
  }

  ngOnDestroy() {
    this.titleSubscription.unsubscribe();
  }

  isCaseManager() {
    return this.userInfo != null && this.userInfo.isCaseManager;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  switchCompany(
    companyId: string,
    redirect = true,
    changeSubscriptions = true
  ) {
    this.localStorage.store('activeCompany', companyId);
    this.contentService.setCompanyId(companyId);

    if (redirect) this.router.navigate(['/cases']);
  }

  companyIsActive(company: UserCompanyDto): boolean {
    return this.contentService.companyId === company.id;
  }

  viewModeIsActive(role: Role): boolean {
    return this.userInfo.hasViewMode(role);
  }

  translateViewMode(role: Role): string {
    return UserInfoDto.translate(role);
  }

  switchViewMode(newMode: Role, changeSubscriptions = true): void {
    this.localStorage.store('activeViewMode', newMode);
    this.userInfo.viewMode = newMode;
  }

  get availableViewModes(): Role[] {
    return this.userInfo.userRoles;
  }

  get sortedPresence(): PresenceDto[] {
    return orderBy(
      this.presence,
      ['ecus', 'companies', 'name'],
      ['desc', 'asc', 'asc']
    );
  }
}
