import { Component, OnInit } from '@angular/core';

import { ContentTitleService } from 'app/content-title.service';

@Component({
  selector: '[ecom-no-access]',
  templateUrl: './no-access.page.html'
})
export class NoAccessPage implements OnInit {
  constructor(private contentService: ContentTitleService) {}

  ngOnInit() {
    this.contentService.setTitle('Ingen tillgång', 'Ingen tillgång');
    // FIXME: Report to the API so we can log and maybe fix
  }
}
