<ng-container *ngIf="userInfo && showLayout">
  <!-- Mitt konto -->
  <div class="topbar-login top-nav-design uk-position-absolute">
    <button
      class="uk-button uk-button-link uk-position-absolute account-btn"
      type="button"
    >
      Mitt konto <i class="fa fa-user"></i>
    </button>
    <div uk-dropdown="mode: click">
      <div>
        {{ userInfo.name }}
        <ng-container
          *ngIf="userInfo.companies && userInfo.companies.length == 1"
        >
          <br />
          <span class="inactive">{{ userInfo.companies[0].name }}</span>
        </ng-container>
      </div>
      <ul class="uk-nav uk-dropdown-nav">
        <ng-container *ngIf="userInfo.hasMultipleUserRoles">
          <li
            [class.uk-active]="viewModeIsActive(viewMode)"
            *ngFor="let viewMode of availableViewModes"
          >
            <a (click)="switchViewMode(viewMode)">{{
              translateViewMode(viewMode)
            }}</a>
          </li>
        </ng-container>
        <ng-container *ngIf="userInfo.companies">
          <li
            [class.uk-active]="companyIsActive(company)"
            *ngFor="let company of userInfo.companies"
          >
            <a
              (click)="switchCompany(company.id)"
              *ngIf="userInfo.companies.length > 1"
              >{{ company.name }}</a
            >
          </li>
          <li class="uk-nav-divider"></li>
        </ng-container>
        <li>
          <a
            [routerLink]="['/account', 'settings']"
            routerLinkActive="uk-active"
            >Mina inställningar</a
          >
        </li>
        <li>
          <a
            [routerLink]="['/account', 'hotkeys']"
            routerLinkActive="uk-active"
            >{{ 'navigation.hotkeys' | translate }}</a
          >
        </li>
        <li><a (click)="logOut()">Logga ut</a></li>
      </ul>
    </div>
  </div>
  <!-- end Mitt konto -->
  <div class="side-bar" id="userBar">
    <div
      data-uk-tooltip="pos:left"
      title="Visa/dölj"
      class="toggleUserMenu"
      uk-toggle="target:#userBar; cls:shrink"
    >
      <a
        href="#"
        uk-toggle="target: #userBarlink; cls: fa-angle-right fa-angle-left"
      >
        <i id="userBarlink" class="fa fa-angle-left"></i>
      </a>
    </div>
    <div class="side-bar-item-holder">
      <div>
        <a
          routerLink="/cases"
          routerLinkActive="activeMenu"
          [requireRoles]="[Roles.CaseManager, Roles.FirstLine, Roles.Customer]"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-folder side-bar-item-icon uk-width-1-3"
            data-uk-tooltip="pos:right"
            [title]="'navigation.cases' | translate"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.cases' | translate
          }}</span>
        </a>
      </div>
      <div>
        <a
          routerLink="/warehouse"
          routerLinkActive="activeMenu"
          [requireRoles]="[Roles.CaseManager]"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-archive side-bar-item-icon uk-width-1-3"
            data-uk-tooltip="pos: right"
            [title]="'navigation.warehouse' | translate"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.warehouse' | translate
          }}</span>
        </a>
      </div>
      <div>
        <a
          routerLink="/tw"
          routerLinkActive="activeMenu"
          [requireRoles]="[Roles.CaseManager]"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-balance-scale side-bar-item-icon uk-width-1-3"
            data-uk-tooltip="pos: right"
            [title]="'navigation.transactionalwarehouse' | translate"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.transactionalwarehouse' | translate
          }}</span>
        </a>
      </div>
      <div>
        <a
          routerLink="/financials"
          routerLinkActive="activeMenu"
          [requireRoles]="[Roles.CaseManager]"
          [adminRoles]="[AdminRoles.FinancialsAdministration]"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-usd side-bar-item-icon uk-width-1-3"
            data-uk-tooltip="pos: right"
            [title]="'navigation.financials' | translate"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.financials' | translate
          }}</span>
        </a>
      </div>
      <div>
        <a
          [routerLink]="['/admin']"
          routerLinkActive="activeMenu"
          [requireRoles]
          [adminRoles]="[AdminRoles.All]"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-list side-bar-item-icon uk-width-1-3"
            [title]="'navigation.admin' | translate"
            data-uk-tooltip="pos: right"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.admin' | translate
          }}</span>
        </a>
      </div>
      <div>
        <a
          [routerLink]="['/ocr']"
          routerLinkActive="activeMenu"
          [requireRoles]="[Roles.CaseManager]"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-braille side-bar-item-icon uk-width-1-3"
            [title]="'navigation.ocr' | translate"
            data-uk-tooltip="pos: right"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.ocr' | translate
          }}</span>
        </a>
      </div>
      <div>
        <a
          [routerLink]="['/integrations']"
          routerLinkActive="activeMenu"
          class="uk-grid uk-grid-collapse uk-flex-middle"
        >
          <i
            class="fa fa-cogs side-bar-item-icon uk-width-1-3"
            [title]="'navigation.integrations' | translate"
            data-uk-tooltip="pos: right"
          ></i>
          <span class="uk-width-2-3 d-none-shrink uk-text-nowrap">{{
            'navigation.integrations' | translate
          }}</span>
        </a>
      </div>
    </div>
    <a
      class="logo d-none-shrink"
      [routerLink]="['/about']"
      routerLinkActive="activeMenu"
    >
      <!-- Ecus ECOM -->
      <img
        src="https://ecus.se/wp-content/uploads/2019/07/logo_ecus_web.png"
        alt=""
      />
    </a>
    <div class="version-box d-none-shrink">
      Version : {{ version }} / API: {{ apiVersion | async }}
    </div>
  </div>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</ng-container>
<ng-container *ngIf="userInfo && !showLayout">
  <div class="uk-flex heightAll holder">
    <router-outlet></router-outlet>
  </div>
</ng-container>
<ecom-error-toaster></ecom-error-toaster>
